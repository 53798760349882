.seats {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
  }

  .seats-input {
    width: 70px;
    border-radius: 20px;
    margin-left: 10px;
    text-align: center;
    font-size: 20px;
    border: none !important;
    padding: 5px;
  }
  

  