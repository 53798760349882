.SM_heading {
  width: 50%;
  font-size: 22px;
}

.SM_main_container {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  
}



