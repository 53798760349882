.SS_heading {
  width: 100%;
  font-size: 22px;
}

.SS_main_container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
}


