.container {
  width: 80%;
  margin: 30px auto;
  padding: 30px 30px 0px 30px;
  background-color: #0e0c0c9a;
  border-radius: 5px;
  color: var(--app-color);
  box-shadow: 4px 7px 13px #4c4c4c6b;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.container .selection_container {
  width: 100%;
}

.select_movie_component{
  flex-grow: 1;
}

.BN-btn{
  padding: 10px;
  border: none;
  margin-top: 20px;
  left: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  color: var(--app-color);
  font-weight: bold;
  background-color: var(--app-Btn);
  border: 2px solid var(--app-color);
  position: relative;
  box-shadow: 5px 4px 0px var(--app-color);
  margin-bottom: 10px;
  transition: 0.5s;
}

.BN-btn:hover{
  color: var(--app-Background);
  background-color: var(--app-color);
  border: 2px solid  var(--app-Background);
  position: relative;
  box-shadow: none;
  margin-bottom: 10px;
  border-radius: 30px;
}



.wrapper{
  display: flex;
  flex-grow: 1;
  
}

.time_seats_container{
  position: relative;
  overflow: auto;
  top: -10px;
}

@media screen and (max-width: 1300px) {
 .wrapper{
  flex-direction: column-reverse;
 } 
 
.time_seats_container{
  top: 0px;
}
 .last_booking_details_container{
  margin-bottom: 20px;
 }
}

@media screen and (max-width: 500px) {
  .container{
    width: 95%;
    padding: 15px;
  }
}

