.last_booking_details_container {
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid  rgba(255, 255, 255, 0.671);  
  box-shadow: 0px 2px 19px #0000003b;
  border-color: -moz-linear-gradient(top, #555555, #111111);
  background-color: var(--app-Background);

}


.last_booking_details_header {
  font-size: 22px;
  margin-bottom: 10px;
}

.seats_container {
  margin-bottom: 10px;
}

.seats_header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.seats_container .seats {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.seat_value {
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--app-color) ;
  margin-right: 10px;
  color: var(--app-Background);
  margin-bottom: 10px;
  text-align: center;
}

.slot,
.movie {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.slot span,
.movie span {
  font-weight: 400;
  font-size: 16px;
}




.last_booking_details_container .no_previous_booking_msg {
  font-size: 15px;
  width: 50%;
  
}
