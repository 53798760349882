* {
  margin: 0px;
  padding: 0px;
 
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  --app-color :#c6ff1a;
  --app-Background :rgb(32, 31, 31);
  --app-Btn :rgb(32, 31, 31);
}

body {
  background-image: url(back.png) ;
  background-repeat: no-repeat; 
  background-position: center top;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
